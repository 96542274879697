import { ClientType } from 'common/types';
import { ClientActionTypes } from './constants';

export type ClientActionType = {
    type:
        | ClientActionTypes.CLIENT_SAVE
        | ClientActionTypes.CLIENT_RESET
    payload: {} | string;
};

export const clientSave = (type: string, data: ClientType): ClientActionType => ({
    type: ClientActionTypes.CLIENT_SAVE,
    payload: { data },
});

export const clientReset = (): ClientActionType => ({
    type: ClientActionTypes.CLIENT_RESET,
    payload: {},
});
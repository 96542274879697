import { ClientType } from 'common/types';
import { ClientActionTypes } from './constants';

const INIT_STATE = {
    client: {},
};

type ClientActionType = {
    type:
    | ClientActionTypes.CLIENT_SAVE
    | ClientActionTypes.CLIENT_RESET
    payload: {
        actionType?: string;
        data?: ClientType | {};
        error?: string;
    };
};

type State = {
    client?: ClientType | {};
};


const Client = (state: State = INIT_STATE, action: ClientActionType) => {
    switch (action.type) {
        case ClientActionTypes.CLIENT_SAVE:
            return { ...state, loading: true, userLoggedIn: false };
        case ClientActionTypes.CLIENT_RESET:
            return {
                ...state,
                loading: false,
                error: false,
                userSignUp: false,
                userLoggedIn: false,
                passwordReset: false,
                passwordChange: false,
                resetPasswordSuccess: null,
            };
        default:
            return { ...state };
    }
};

export default Client;

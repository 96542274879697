import { loginUrls } from 'appConstants';
import axios from 'axios';
import { APICore } from './apiCore';

const api = new APICore();


//TODO login de usuario
function login(params: { username: string; password: string }) {
    //return  api.create('/login/', params);
    // const salt = bcrypt.genSaltSync(9);
    // const passHash = bcrypt.hashSync(password, salt)
   return axios.post(loginUrls.SIGNIN, params)
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { forgotPassword, forgotPasswordConfirm, login, logout, signup };


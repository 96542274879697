import { SagaIterator } from '@redux-saga/core';
import { ClientType } from 'common/types';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { clientReset, clientSave } from './actions';
import { ClientActionTypes } from './constants';


type clientData = {
    type: string;
    payload: {
        client: ClientType;
    };
};

function* saveUser({ payload: { client }, type}: clientData): SagaIterator {
    try {
        yield put(clientSave(ClientActionTypes.CLIENT_SAVE, client));

    } catch (error: any) {
        yield put(clientReset());
    }
}
function* resetUser(): SagaIterator {
    try {
        yield put(clientReset());

    } catch (error: any) {
        yield put(clientReset());
    }
}

export function* watchClient() {
    yield takeEvery(ClientActionTypes.CLIENT_SAVE, saveUser);
}
export function* watchReset() {
    yield takeEvery(ClientActionTypes.CLIENT_RESET, resetUser);
}

function* authSaga() {
    yield all([fork(watchClient)]);
}

export default authSaga;

import { AllRoles, Roles } from 'appConstants';
import { DefaultLayout, VerticalLayout } from 'layouts';
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));

const Login2 = React.lazy(() => import('pages/account/Login2'));
const Logout2 = React.lazy(() => import('pages/account/Logout2'));
const Register2 = React.lazy(() => import('pages/account/Register2'));
const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('pages/account/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('pages/account/LockScreen2'));

// error
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// dashboard
const BlankPage = React.lazy(() => import('pages/other/Blank'));


// clientes
const MisClientes = React.lazy(() => import('pages/clients/my_clients'));
const Seguimientos = React.lazy(() => import('pages/clients/follow_up'));
const NuevoCliente = React.lazy(() => import('pages/clients/new_client'));
const EditarCliente = React.lazy(() => import('pages/clients/edit_client'));
const DetalleCliente = React.lazy(() => import('pages/clients/detail_clients'));
const ConsultarCliente = React.lazy(() => import('pages/clients/consult_client'));

// clinicas
const MisClinicas = React.lazy(() => import('pages/centers/my_centers'));
const NuevaClinica = React.lazy(() => import('pages/centers/new_center'));
const EditarClinica = React.lazy(() => import('pages/centers/edit_center'));

// compañias
const MisCompañias = React.lazy(() => import('pages/companies/my_companies'));
const NuevaCompañia = React.lazy(() => import('pages/companies/new_company'));
const EditarCompañia = React.lazy(() => import('pages/companies/edit_company'));

// empleados
const MisEmpleados = React.lazy(() => import('pages/employees/my_employees'));
const NuevoEmpleado = React.lazy(() => import('pages/employees/new_employee'));
const EditarEmpleado = React.lazy(() => import('pages/employees/edit_employee'));

// electro
const ConsultaElectro = React.lazy(() => import('pages/electro/consultation'));
const CitaElectro = React.lazy(() => import('pages/electro/first_date'));
const SesionElectro = React.lazy(() => import('pages/electro/sesion'));
const DetailElectro = React.lazy(() => import('pages/electro/details'));

// laser
// const ConsultaLaser = React.lazy(() => import('pages/laser/consultation'));
// const CitaLaser = React.lazy(() => import('pages/laser/first_date'));
// const SesionLaser = React.lazy(() => import('pages/laser/sesion'));

// - other
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    // TODO types layout
    // - HorizontalLayout;
    // - DetachedLayout;
    // - FullLayout;
    // - VerticalLayout;

    let Layout = VerticalLayout;

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'login2', element: <LoadComponent component={Login2} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
        {
            // auth protected routes super-admin / admin
            path: '/',
            element: <PrivateRoute roles={[Roles.ADMIN_ROLE, Roles.SUPERADMIN_ROLE]} component={Layout} />,
            children: [

                {
                    path: 'dashboard',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={BlankPage} />,
                        },
                    ],
                },
                {
                    path: 'companias',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={MisCompañias} />,
                        },
                        {
                            path: 'nueva-compania',
                            element: <LoadComponent component={NuevaCompañia} />,
                        },
                        {
                            path: 'editar-compania/:id',
                            element: <LoadComponent component={EditarCompañia} />,
                        },

                    ],
                },
                {
                    path: 'clinicas',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={MisClinicas} />,
                        },
                        {
                            path: 'nueva-clinica',
                            element: <LoadComponent component={NuevaClinica} />,
                        },
                        {
                            path: 'editar-clinica/:id',
                            element: <LoadComponent component={EditarClinica} />,
                        },

                    ],
                },
                {
                    path: 'empleados',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={MisEmpleados} />,
                        },
                        {
                            path: 'nuevo-empleado',
                            element: <LoadComponent component={NuevoEmpleado} />,
                        },
                        {
                            path: 'editar-empleado/:id',
                            element: <LoadComponent component={EditarEmpleado} />,
                        },
                    ],
                },
            ]
        },
        {
            // auth protected routes tecnico
            path: '/',
            element: <PrivateRoute roles={AllRoles} component={Layout} />,
            children: [
                {
                    path: 'clientes',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={MisClientes} />,
                        },
                        {
                            path: 'seguimientos',
                            element: <LoadComponent component={Seguimientos} />,
                        },
                        {
                            path: 'nuevo-cliente',
                            element: <LoadComponent component={NuevoCliente} />,
                        },
                        {
                            path: 'editar-cliente/:idClient',
                            element: <LoadComponent component={EditarCliente} />,
                        },
                        {
                            path: 'consultar-cliente/:idClient',
                            element: <LoadComponent component={ConsultarCliente} />,
                        },
                        {
                            path: 'detalle-cliente/:idClient',
                            element: <LoadComponent component={DetalleCliente} />,
                        },
                    ],
                },
                {
                    path: 'electro',
                    children: [
                        {
                            path: 'consulta/:idClient',
                            element: <LoadComponent component={ConsultaElectro} />,
                        },
                        {
                            path: 'cita/:idCompose',
                            element: <LoadComponent component={CitaElectro} />,
                        },
                        {
                            path: 'sesion/:idCompose',
                            element: <LoadComponent component={SesionElectro} />,
                        },
                        {
                            path: 'detalles/:idCompose',
                            element: <LoadComponent component={DetailElectro} />,
                        },
                    ],
                },
                // {
                //     path: 'laser',
                //     children: [
                //         {
                //             path: 'consulta/:id',
                //             element: <LoadComponent component={ConsultaLaser} />,
                //         },
                //         {
                //             path: 'cita/:id',
                //             element: <LoadComponent component={CitaLaser} />,
                //         },
                //         {
                //             path: 'sesion/:id',
                //             element: <LoadComponent component={CitaLaser} />,
                //         },
                //         {
                //             path: 'seguimiento',
                //             element: <LoadComponent component={Maintenance} />,
                //         },
                //     ],
                // },
                {
                    path: 'facial',
                    children: [
                        {
                            path: 'consulta',
                            element: <LoadComponent component={Maintenance} />,
                        },
                        {
                            path: 'cita',
                            element: <LoadComponent component={Maintenance} />,
                        },
                        {
                            path: 'sesion',
                            element: <LoadComponent component={Maintenance} />,
                        },
                        {
                            path: 'seguimiento',
                            element: <LoadComponent component={Maintenance} />,
                        },
                    ],
                },
                {
                    path: 'corporal',
                    children: [
                        {
                            path: 'consulta',
                            element: <LoadComponent component={Maintenance} />,
                        },
                        {
                            path: 'cita',
                            element: <LoadComponent component={Maintenance} />,
                        },
                        {
                            path: 'sesion',
                            element: <LoadComponent component={Maintenance} />,
                        },
                        {
                            path: 'seguimiento',
                            element: <LoadComponent component={Maintenance} />,
                        },
                    ],
                },
            ]
        },
    ]);
};

export { AllRoutes };


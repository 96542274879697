
export const BASE_URL = process.env.REACT_APP_LOCAL ? "http://173.249.63.71:8080/api" : process.env.REACT_APP_URL_API


export const loginUrls = {
    SIGNIN: `${BASE_URL}/auth/signin`
}

export const clientsUrls = {
    GET_CLIENTS: `${BASE_URL}/clients`
}

export const centersUrls = {
    GET_CENTERS: `${BASE_URL}/centers`
}

export const companiesUrls = {
    GET_COMPANIES: `${BASE_URL}/companies`
}

export const employeesUrls = {
    GET_EMPLOYEES: `${BASE_URL}/users`,
    SIGNUP_EMPLOYEES: `${BASE_URL}/auth/signup`,
    UPDATE_EMPLOYEES: `${BASE_URL}/users`
}

export const businessRulesUrls = {
    GET_BUSINESS_RULES: `${BASE_URL}/business_rules`,
    GENDER: `${BASE_URL}/business_rules?name=gender`,
    APP: `${BASE_URL}/business_rules?name=aplicaciones`,
    COMMENTS: `${BASE_URL}/business_rules?name=comentarios`,
    REASON: `${BASE_URL}/business_rules?name=motivosPausaCancelar`,
    CALCULATE: `${BASE_URL}/business_rules/calculate`
}

export const projectsUrls = {
    PROJECT_BASE: `${BASE_URL}/projects`,
    PROJECT_BY_CLIENT: `${BASE_URL}/projects/client`,
    GET_MONITORING: `${BASE_URL}/projects/monitoring`,
}

export const filesUrls = {
    UPLOAD: `${BASE_URL}/files/upload`,
    GET_FILES: `${BASE_URL}/files/getFiles`,
    DOWNLOAD: `${BASE_URL}/files/getFile`
}

export const emailUrls = {
    SEND: `${BASE_URL}/email/send`,
}

export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
    { key: 'clientes', label: 'Clientes', isTitle: true },
    {
        key: 'clientes',
        label: 'Clientes',
        isTitle: false,
        icon: 'uil-user',
        children: [
            {
                key: 'mis-clientes',
                label: 'Mis clientes',
                url: '/clientes',
                parentKey: 'clientes',
            },
            {
                key: 'clientes-nuevo',
                label: 'Nuevo cliente',
                url: '/clientes/nuevo-cliente',
                parentKey: 'clientes',
            },
            {
                key: 'seguimientos',
                label: 'Seguimientos',
                url: '/clientes/seguimientos',
                parentKey: 'clientes',
            },
        ],
    },
    { key: 'apps', label: 'Apps', isTitle: true },
    {
        key: 'electro',
        label: 'Electro',
        isTitle: false,
        icon: 'uil-bolt',
        // children: [
        //     {
        //         key: 'electro-consulta',
        //         label: 'Consulta',
        //         url: '/electro/consulta',
        //         parentKey: 'electro',
        //     },
        //     {
        //         key: 'electro-cita',
        //         label: '1ª cita',
        //         url: '/electro/cita',
        //         parentKey: 'electro',
        //     },
        //     {
        //         key: 'electro-sesion',
        //         label: 'Sesión',
        //         url: '/electro/sesion',
        //         parentKey: 'electro',
        //     },
        //     {
        //         key: 'electro-historico',
        //         label: 'Historico',
        //         url: '/electro/historico',
        //         parentKey: 'electro',
        //     },
        // ],
    },
    {
        key: 'laser',
        label: 'Láser',
        isTitle: false,
        icon: 'uil-pen',
        // children: [
        //     {
        //         key: 'laser-consulta',
        //         label: 'Consulta',
        //         url: '/laser/consulta',
        //         parentKey: 'laser',
        //     },
        //     {
        //         key: 'laser-cita',
        //         label: '1ª cita',
        //         url: '/laser/cita',
        //         parentKey: 'laser',
        //     },
        //     {
        //         key: 'laser-sesion',
        //         label: 'Sesión',
        //         url: '/laser/sesion',
        //         parentKey: 'laser',
        //     },
        //     {
        //         key: 'laser-seguimiento',
        //         label: 'Seguimiento',
        //         url: '/laser/seguimiento',
        //         parentKey: 'laser',
        //     },
        // ],
    },
    {
        key: 'facial',
        label: 'Facial',
        isTitle: false,
        icon: 'uil-smile',
        // children: [
        //     {
        //         key: 'facial-consulta',
        //         label: 'Consulta',
        //         url: '/facial/consulta',
        //         parentKey: 'facial',
        //     },
        //     {
        //         key: 'facial-cita',
        //         label: '1ª cita',
        //         url: '/facial/cita',
        //         parentKey: 'facial',
        //     },
        //     {
        //         key: 'facial-sesion',
        //         label: 'Sesión',
        //         url: '/facial/sesion',
        //         parentKey: 'facial',
        //     },
        //     {
        //         key: 'electro-seguimiento',
        //         label: 'Seguimiento',
        //         url: '/facial/seguimiento',
        //         parentKey: 'facial',
        //     },
        // ],
    },
    // {
    //     key: 'corporal',
    //     label: 'Corporal',
    //     isTitle: false,
    //     icon: 'mdi-human    ',
    //     children: [
    //         {
    //             key: 'corporal-consulta',
    //             label: 'Consulta',
    //             url: '/corporal/consulta',
    //             parentKey: 'corporal',
    //         },
    //         {
    //             key: 'corporal-cita',
    //             label: '1ª cita',
    //             url: '/corporal/cita',
    //             parentKey: 'corporal',
    //         },
    //         {
    //             key: 'corporal-sesion',
    //             label: 'Sesión',
    //             url: '/corporal/sesion',
    //             parentKey: 'corporal',
    //         },
    //         {
    //             key: 'corporal-seguimiento',
    //             label: 'Seguimiento',
    //             url: '/corporal/seguimiento',
    //             parentKey: 'corporal',
    //         },
    //     ],
    // },
];

const MENU_ITEMS_ADMIN: MenuItemType[] = [
    { key: 'administracion', label: 'Administración', isTitle: true },
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        icon: 'uil-chart-line',
        url: '/dashboard',
    },
    {
        key: 'companias',
        label: 'Compañías',
        isTitle: false,
        icon: 'uil-building',
        children: [
            {
                key: 'perfil-companias',
                label: 'Mis compañías',
                url: '/companias',
                parentKey: 'companias',
            },
            {
                key: 'nueva-compania',
                label: 'Nueva compañía',
                url: '/companias/nueva-compania',
                parentKey: 'companias',
            },
        ],
    },
    {
        key: 'clinicas',
        label: 'Clinicas',
        isTitle: false,
        icon: 'uil-store-alt',
        children: [
            {
                key: 'perfil-clinicas',
                label: 'Mis clinicas',
                url: '/clinicas',
                parentKey: 'clinicas',
            },
            {
                key: 'nueva-clinica',
                label: 'Nueva clinica',
                url: '/clinicas/nueva-clinica',
                parentKey: 'clinicas',
            },
        ],
    },
    {
        key: 'empleados',
        label: 'Empleados',
        isTitle: false,
        icon: 'uil-user-square',
        children: [
            {
                key: 'mis-empleados',
                label: 'Mis empleados',
                url: '/empleados',
                parentKey: 'empleados',
            },
            {
                key: 'nuevo-empleado',
                label: 'Nuevo empleado',
                url: '/empleados/nuevo-empleado',
                parentKey: 'empleados',
            },
        ],
    },
]
const MENU_ITEMS_COMPLETE: MenuItemType[] = []
MENU_ITEMS_COMPLETE.unshift(...MENU_ITEMS_ADMIN, ...MENU_ITEMS)

export { MENU_ITEMS, MENU_ITEMS_COMPLETE };


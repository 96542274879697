import { SagaIterator } from '@redux-saga/core';
import { Roles } from 'appConstants';
import {
    forgotPassword as forgotPasswordApi,
    login as loginApi,
    signup as signupApi
} from 'helpers';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authApiResponseError, authApiResponseSuccess } from './actions';
import { AuthActionTypes } from './constants';

type UserData = {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
    };
    type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */

//TODO logica para recuperar el token y los datos del usuario modificar a nuestro body
function* login({ payload: { username, password }, type }: UserData): SagaIterator {
    try {

        const response = yield call(loginApi, { username, password });
        // console.log(response)
        var user = response.data;
        //TODO limpiar esto
        const fakeCookieAdmin = "v311t3c=eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY4ODU3Mjg3NiwiZXhwIjoxNjg4NjU5Mjc2LCJpZCI6MSwidXNlcm5hbWUiOiJhZG1pbiIsImVtYWlsIjoiYWRtaW5AdGVzdC5jb20iLCJyb2xlIjoiQURNSU4iLCJuYW1lIjoidGVzdCIsInN1cm5hbWUiOiJhZG1pbiIsInBob25lIjoiNjQ0NDQ0NDQ0IiwicG9zaXRpb24iOjYsImFjdGl2ZSI6dHJ1ZSwiY3JlYXRpb25EYXRlIjoiMjAyMy0wOC0yNCIsImNlbnRlcklkIjoxLCJhcHBsaWNhdGlvbnMiOnsiZWxlY3RybyI6MCwiZmFjaWFsIjoxLCJjb3Jwb3JhbCI6MiwibGFzZXIiOjF9fQ.vbXjyuD2OmkQIywDNapLxS9yXWKcG3Hkj_BsrxxaCo7pdufSngbGfHyxV7mqot693-juj8S-eFUqJP6dtyO27A; Path=/api; Max-Age=86400; Expires=Thu, 07 Sep 2023 21:44:14 GMT; HttpOnly"
        const fakeCookieTecnico = "v311t3c=eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZXBlIiwiaWF0IjoxNjg4NTcyODc2LCJleHAiOjE2ODg2NTkyNzYsImlkIjoxLCJ1c2VybmFtZSI6InRlY25pY28iLCJlbWFpbCI6InRlY25pY29AdGVzdC5jb20iLCJyb2xlIjoiVEVDTklDTyIsIm5hbWUiOiJ0ZXN0Iiwic3VybmFtZSI6InRlY25pY28iLCJwaG9uZSI6IjY0NDQ0NDQ0NCIsInBvc2l0aW9uIjo5LCJhY3RpdmUiOnRydWUsImNyZWF0aW9uRGF0ZSI6IjIwMjMtMDgtMjQiLCJjZW50ZXJJZCI6MSwiYXBwbGljYXRpb25zIjp7ImVsZWN0cm8iOjAsImZhY2lhbCI6MSwiY29ycG9yYWwiOjIsImxhc2VyIjoxfX0.gzfXBlAz21YzcIKTz-lsj1FMSzkURRr_pT6Y60zf99kea_7atuVV8l1BnH76DvkKj85_d_5HPJjDmlp_UyICqg; Path=/api; Max-Age=86400; Expires=Thu, 07 Sep 2023 21:44:14 GMT; HttpOnly"
        const fakeCookie = user.role === Roles.TECHNIC_ROLE ? fakeCookieTecnico : fakeCookieAdmin
        if (user && user.token) {
            api.setLoggedInUser(user);
            setAuthorization(user.token);
        } else {
            const cookie = response.config.headers.Cookies ? response.config.headers.Cookies : fakeCookie;
            const token = cookie.split(" ")[0].slice(8, -1)
            user = { ...user, token: token }
            console.log(user)
            api.setLoggedInUser(user);
            setAuthorization(cookie);
        }

        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error: any) {
        console.log(error)

        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { fullname, email, password } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, { fullname, email, password });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { username });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}
export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword)]);
}

export default authSaga;

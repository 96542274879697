
//Una implementación del Estandard Official: RFC 5322: ( valida en el 99.99% de los emails existentes )
const EMAIL_REGEX = new RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/);

//Los nombres de usuario pueden contener caracteres a-z, 0-9, guiones bajos y puntos. El nombre de usuario no puede comenzar con un punto ni terminar con un punto. Tampoco debe tener más de un período secuencialmente. La longitud máxima es de 30 caracteres.
const NICK_REGEX = new RegExp(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/);

//La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, una minúscula, una mayúscula y un caracter especial.
const PASS_REGEX = new RegExp(/^(?=.*\d)(?=.*[\u0021-\u002e\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/);

// regex para evitar que se introduzca mas de un espacio en blanco en los campos name y username
const ONE_BLANK_REGEX = new RegExp(/\s+/g);

// regex numero de teléfono
// const PHONE_REGEX = new RegExp(/^(\+?\d{9})$/);
const PHONE_REGEX = new RegExp(/^(\+?\d*)$/);

// regex cif
const CIF_REGEX = new RegExp(/^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/);

//regex nif
const NIF_REGEX = new RegExp(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/);

//regex nie
const NIE_REGEX = new RegExp(/^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/);

export { CIF_REGEX, EMAIL_REGEX, NICK_REGEX, NIE_REGEX, NIF_REGEX, ONE_BLANK_REGEX, PASS_REGEX, PHONE_REGEX };


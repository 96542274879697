import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { User } from 'common/types';


const TOKEN_TECNICO =
'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZXBlIiwiaWF0IjoxNjg4NTcyODc2LCJleHAiOjE2ODg2NTkyNzYsImlkIjoxLCJ1c2VybmFtZSI6InBlcGUtdGVjbmljbyIsImVtYWlsIjoibWFudWVsQGdtYWlsLmNvbSIsInJvbGUiOiJUZWNuaWNvIiwibmFtZSI6InBlcGUtdGVjbmljbyIsInN1cm5hbWUiOiJQw6lyZXoiLCJwaG9uZSI6IjY0NDQ0NDQ0NCIsInBvc2l0aW9uIjoiVEVDTklDTyIsImFjdGl2ZSI6dHJ1ZSwiY3JlYXRpb25EYXRlIjoiMjAyMy0wOC0yNCIsImNlbnRlcklkIjoxLCJhcHBsaWNhdGlvbnMiOnsiZWxlY3RybyI6MCwiZmFjaWFsIjoxLCJjb3Jwb3JhbCI6MiwibGFzZXIiOjF9fQ.4Cz60Tda5qAb7Rm03d1WnczSuBYgr_G65oO6aS0miLId63EWcbrd7f1RoMouWtkwpFbDh1w6ZUtEahVL1k8ieQ';

const TOKEN_ADMIN =
    'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZXBlIiwiaWF0IjoxNjg4NTcyODc2LCJleHAiOjE2ODg2NTkyNzYsImlkIjoxLCJ1c2VybmFtZSI6InBlcGUiLCJlbWFpbCI6Im1hbnVlbEBnbWFpbC5jb20iLCJyb2xlIjoiQURNSU4iLCJuYW1lIjoiTWFudWVsIiwic3VybmFtZSI6IlDDqXJleiIsInBob25lIjoiNjQ0NDQ0NDQ0IiwicG9zaXRpb24iOiJlbmNhcmdhZG8iLCJhY3RpdmUiOnRydWUsImNyZWF0aW9uRGF0ZSI6IjIwMjMtMDgtMjQiLCJjZW50ZXJJZCI6MSwiYXBwbGljYXRpb25zIjp7ImVsZWN0cm8iOjAsImZhY2lhbCI6MSwiY29ycG9yYWwiOjIsImxhc2VyIjoxfX0.xpgoE9NoseDeuzX9yW96RMkw3DI12-op3pzLoi17qZIjaAYSMQ4UvcwMfrzFnyxipLUaEC9xGlUAYGZlcNjcTA';

var mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });

export function configureFakeBackend() {
    let users: User[] = [
        {
            id: 3,
            username: 'tecnico',
            password: 'tecnico',
            name: 'miguel',
            surname: 'de cervantes',
            token: TOKEN_TECNICO,
        },
        {
            id: 2,
            username: 'admin',
            password: 'admin',
            name: 'raquel',
            surname: 'de la morena',
            token: TOKEN_ADMIN,
        },
        {
            id: 1,
            username: "pepe",
            password:"12345",
            name: "Manuel",
            surname: "Pérez",
            token: TOKEN_ADMIN,
        },
    ];

    mock.onPost('/login/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.username === params.username && user.password === params.password;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let user = filteredUsers[0];
                    resolve([200, user]);
                } else {
                    // else return error
                    resolve([401, { message: 'Username or password is incorrect' }]);
                }
            }, 1000);
        });
    });

    mock.onPost('/register/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // add new users
                let [firstName, lastName] = params.fullname.split(' ');
                let newUser: User = {
                    id: users.length + 1,
                    username: firstName,
                    password: params.password,
                    name: firstName,
                    surname: lastName,
                    token: TOKEN_TECNICO,
                };
                users.push(newUser);

                resolve([200, newUser]);
            }, 1000);
        });
    });

    mock.onPost('/forget-password/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.username === params.username;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let responseJson = {
                        message: "We've sent you a link to reset password to your registered email.",
                    };
                    resolve([200, responseJson]);
                } else {
                    // else return error
                    resolve([
                        401,
                        {
                            message: 'Sorry, we could not find any registered user with entered username',
                        },
                    ]);
                }
            }, 1000);
        });
    });
}

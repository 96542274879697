const config = {
    API_URL: process.env.REACT_APP_API_URL,
    DISTANCE_SESSION_ACTIVE: process.env.REACT_APP_DISTANCE_SESSION_ACTIVE || "true",
    DISTANCE_PERCENT: process.env.REACT_APP_DISTANCE_PERCENT || "70",
    FACIAL_KEYS: process.env.REACT_APP_FACIAL_KEYS?.split(",") || [],
    BODY_FEM_KEYS: process.env.REACT_APP_BODY_FEM_KEYS?.split(",") || [],
    BODY_MASC_KEYS: process.env.REACT_APP_BODY_MASC_KEYS?.split(",") || [],
    ZONES_ORDERER_KEYS: process.env.REACT_APP_ZONES_ORDERER_KEYS?.split(",") || []
};

export default config;

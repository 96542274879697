import { MENU_ITEMS, MENU_ITEMS_COMPLETE, MenuItemType, Roles } from 'appConstants';

const getMenuItems = (role: string) => {
    
    //TODO agregando menu items admin segun role
    let roles: string[] = [Roles.ADMIN_ROLE, Roles.SUPERADMIN_ROLE]
    if (role && roles.indexOf(role) === -1) {
        return MENU_ITEMS
    }else{
        return MENU_ITEMS_COMPLETE
    }

};

const findAllParent = (menuItems: MenuItemType[], menuItem: MenuItemType): string[] => {
    let parents: string[] = [];
    const parent = findMenuItem(menuItems, menuItem['parentKey']);

    if (parent) {
        parents.push(parent['key']);
        if (parent['parentKey']) parents = [...parents, ...findAllParent(menuItems, parent)];
    }

    return parents;
};

const findMenuItem = (
    menuItems: MenuItemType[] | undefined,
    menuItemKey: MenuItemType['key'] | undefined
): MenuItemType | null => {
    if (menuItems && menuItemKey) {
        for (var i = 0; i < menuItems.length; i++) {
            if (menuItems[i].key === menuItemKey) {
                return menuItems[i];
            }
            var found = findMenuItem(menuItems[i].children, menuItemKey);
            if (found) return found;
        }
    }
    return null;
};

export { getMenuItems, findAllParent, findMenuItem };

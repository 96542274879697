import { Token } from 'common/types';
import { APICore } from 'helpers/api/apiCore';
import useUserToken from 'hooks/useUserToken';
import { Navigate, useLocation } from 'react-router-dom';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const tokenUser: Token = useUserToken() as unknown as Token;
    const api = new APICore();

    /**
     * not logged in so redirect to login page with the return url
    */
    if (api.isUserAuthenticated() === false) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }
    // check if route is restricted by role PRO STYLE
    if (roles && roles.indexOf(tokenUser.role) === -1) {
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/' }} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
